

import { useEffect, useState, useRef } from 'react';
import './ContactUs.css';
import ContactUS from '../../aImages/contactUs.jpg';
import { useToast } from '../../CustomeHooks/useToast';
import axios from 'axios';
import Cookie from 'js-cookie';
import validator from 'validator';
import { isValidToken } from '../../Javascript_Functions_to_export/Utils';

import TextReco from '../TextReco/TextReco';
import { ToastProvider } from '../Toast/ToastContext';

//import ReCAPTCHA from 'react-google-recaptcha';
//import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";

//import getMAC, { isMAC } from 'getmac';

const ContactUs = () => {

    const toast = useToast();

    const [userName, setUserName] = useState('');
    const [userNameError, setUserNameError] = useState('');

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState('');

    const [isSending, setIsSending] = useState(false);
    
    const [defaultValue, setDefaultValue] = useState('');

    const _siteKey = "6LfK5nUqAAAAALrsHTy6oU8161TUi_B3q468PWob";
    const _secretKey = "6LfK5nUqAAAAAL5lbsZZ34s_j7e2RyEcdM4Wg2AE";
    const recaptcha = useRef();

    useEffect(() => {

        var _token = null;
        if(Cookie.get('token'))
            _token = Cookie.get('token');
        else if(Cookie.get('adminToken'))
            _token = Cookie.get('adminToken');

        // User already has logged in, BUT the cookie has expired
        if(!_token && sessionStorage.getItem('Logo')) {
            alert("Session has expired, please Login again");
            sessionStorage.removeItem('Logo');
            window.location.href = process.env.REACT_APP_ORIGIN + "/login";
            return;
        }

        // User has changed or added the token by hand
        if(_token && !isValidToken(_token)) {
            return;
        }
        
        window.scrollTo(0, 0);

        // If user closes the tab || 
        //         closes the browser ||
        //         or open a new tab
        // before the session expired
        // if(_token && !sessionStorage.getItem('Logo')) {
        //     axios.get(`${process.env.REACT_APP_URL}/logout`, { withCredentials: true })
        //     .then(res => {
        //         if(sessionStorage.getItem('Logo'))
        //             sessionStorage.removeItem('Logo');

        //         window.location.href = process.env.REACT_APP_ORIGI + "/login";
        //     })
        //     .catch(err => {
        //         toast('error', err.response.data.message);
        //     })
        // }

    }, []);

    const _setUserName = (e) => {
        // if(e.target.value.trim().length === 0)
        //     setUserNameError('Name is required');
        // else
        //     setUserNameError('');

        setUserName(e.target.value);
    }

    const _setEmail = (e) => {
        // if(e.target.value.trim().length === 0)
        //     setEmailError('Email is required');
        // else
        //     setEmailError('');

        setEmail(e.target.value);
    }

    const _setMessage = (e) => {
        // if(e.target.value.trim().length === 0)
        //     setMessageError('Message is required');
        // else
        //     setMessageError('');

        setMessage(e.target.value);
    }

    // const getMACC = async () => {
    //     const response = await fetch("https://api.ipify.org/");
    //     const IP = await response.text();
    //     console.log(IP);

    //     //return IP;
    // }
    //console.log("");

    const handleSubmit = (e) => {
        //debugger;
        e.preventDefault();
        // add information checking on each field.

        // const captchaValue = recaptcha.current.getValue();
        // if(!captchaValue) {
        //     toast("warning", "Please verify the reCAPTCHA !");
        //     return;
        // }
        //return;
        var _token = null;
        if(Cookie.get('token'))
            _token = Cookie.get('token');
        else if(Cookie.get('adminToken'))
            _token = Cookie.get('adminToken');

        // User already has logged in, BUT the cookie has expired
        if(!_token && sessionStorage.getItem('Logo')) {
            alert("Session has expired, please Login again");
            sessionStorage.removeItem('Logo');
            window.location.href = process.env.REACT_APP_ORIGIN + "/login";
            return;
        }

        // User has changed or added the token by hand
        if(_token && !isValidToken(_token)) {
            return;
        }
        
        if(!userName || (userName && userName.trim() === '')) {
            toast("warning", "Name field is required !");
            return;
        }
        if(!email || (email && email.trim() === '')) {
            toast("warning", "Email field is required !");
            return;
        }
        if(!message || (message && message.trim() === '')) {
            toast("warning", "Message field is required !");
            return;
        }

        if(!validator.isEmail(email)) {
            toast("warning", "Invalid Email !");
            return;
        }

        // Prevent user to send a lot of emails
        if(isMatching && isMatching.current === false) {
            toast("warning", "Text is empty or incorrect !");
            return;
        }

        setIsSending(true);

        var newContactMessage = {
            UserName: userName,
            UserEmail: email,
            Message:  message
        }

        //fetch('http://localhost:808x/contactmebyemail', {
        // fetch(`${process.env.REACT_APP_URL}/contactmebyemail`, {
        //     method: "POST",
        //     mode: "cors",
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify(newContactMessage)
        // })
        
        axios.defaults.withCredentials = true;
        axios.post(process.env.REACT_APP_URL + '/contactmebyemail', newContactMessage)
        .then((res) => {
            //console.log("res", res);

            if(res.data.status && res.data.status !== "success") {
                toast('error', res.data.message);
            }
            else {   
                if(res.data.toLowerCase() === 'success') {
                    toast('success', 'Help is On The Way !' );
                    setUserName('');
                    setEmail('');
                    setMessage('');
                    setDefaultValue(new Date());
                    // set  
                }
                else {
                    toast('error', "Error in sending message !");
                }
            }
        })
        .catch((err) => {
            console.log("inside catch", err);
            //if(err.response.status === 400 || err.response.status === 401) {
                //toast('error', "Error in sending");
            //}
        })
        .finally(() => {
            setIsSending(false);
        });
    }

    //console.log("contactus=", process.env.REACT_CONTACTUS);

    const isMatching = useRef(false);
    const getMatched = (_isMatching) => {
        isMatching.current = _isMatching;
        console.log(isMatching.current);
    }

    const addresses = [
        { 
          id:1,
          image: <i className="fa fa-map-marker" aria-hidden="true"></i>, 
          title: "Address",
          text: "Lebanon - Mount Lebanon - El-Shouf - Main Road - First Floor"
        },
        { 
            id:2,
            //image: <i className="fa fa-envelope-o" aria-hidden="true"></i>, 
            image: <i className="fa fa-instagram" aria-hidden="true"></i>, 
            //title: "Email",
            title: "Instagram",
            text: "hire.me.world", //process.env.REACT_CONTACTUS,  //"ask24x7.hireme@hotmail.com"
        },
        { 
            id:3,
            //image: <i className="fa fa-twitter" aria-hidden="true"></i>,  
            image: <i className="fa fa-facebook-square" aria-hidden="true"></i>,  
            //title: "Twitter",
            title: "Facebook",
            //text: "@hiremeworld",
            text: "hire me world"
        }
    ]

    return(
            <div className='contactus-container every-page-container'>
                {/* <ToastContainer position="bottom-right"/> */}

                <img src={ ContactUS } alt='contactus' className='contactus-img'
                        style={{ width: '100%', height: '300px', position: 'absolute' }}/>
    
                <div className='blue-div'>
                    Contact Us
                </div>
                <div className='home-contactus'>
                    Home &gt; Contact Us
                </div>

                <div className='contactus-content'>
                    <form onSubmit={ handleSubmit }>
                            
                        <div className='fields'>
                            <div className='send-message'>
                                Send Us a Message
                            </div>
                            <div className='feel-free field'>
                                {/* Feel free to contact us and we will get back to you as soon as we can. */}
                                Feel free to contact us and we will get back to you as soon as possible.
                            </div>
                            <div className='username-email field'>
                                <div className='username'>
                                    <input type="text" 
                                                placeholder='Name (required)'
                                                maxLength="50"
                                                value={ userName } 
                                                onChange={ _setUserName }
                                                className='field-itself'/>
                                </div>
                                <div className='email'>
                                    <input type="text" 
                                                placeholder='Your Email (required)'
                                                maxLength="50"
                                                value={ email } 
                                                onChange={ _setEmail } 
                                                className='field-itself' />
                                            {/* { emailError !== '' ? 
                                            <span className="email-error">
                                                { emailError }
                                            </span> : 
                                            null } */}
                                </div>
                            </div>
                            <div className='message field'>
                                    <textarea cols='50' 
                                                rows='16' 
                                                maxLength="700"
                                                placeholder='Message (required)'
                                                value={ message } 
                                                onChange={ _setMessage }
                                                className='field-itself'
                                                style={{
                                                    fontFamily: 'arial, "Times New Roman", serif' 
                                                }}>
                                        </textarea>
                                        {/* { messageError !== '' ? 
                                        <span className="textarea-error">
                                            { messageError }
                                        </span> : 
                                        null } */}
                            </div>
                            <div className='submitbutton field'>
                                
                            <TextReco MatchingFn = { getMatched } defaultValue = { defaultValue }/>
                            {/* <ReCAPTCHA ref={recaptcha} sitekey="6LdT6nUqAAAAAF-x2JgfZNO7oztY-0WAmwnhKG2H" /> */}
                            
                            <input name='contact-us-submit'
                                   type='submit' 
                                   className={`submit-input ${isSending ? 'disabled' : ''}`}
                                   value={`${isSending ? 'Sending ...' : 'Send Message'}`} 
                                   disabled={ isSending } />


                            {/* {
                                !isSending ?
                                <input type='submit' 
                                       value='Send Message' 
                                       style={{ cursor: 'pointer', border: 'solid 0' }}
                                       className='submit-input' />
                                :
                                <button type='button'
                                        style={{ cursor: 'not-allowed', border: 'solid 0' }}
                                        className='submit-input disabled'>
                                    Sending
                                </button>
                            } */}
                            </div>
                        </div>
                    </form>

                    <div className='addresses'>
                            {
                                addresses.map((address, key) => {
                                    return(<div className='row' key={key}>
                                            <div className='logo'>
                                                { address.image }
                                            </div>
                                            <div className='textes'>
                                                <div className='big'>{ address.title }</div>
                                                <div className='small'>{ address.text }</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                       
                    </div>
                    
                </div>

                {/* <i className="fa fa-caret-right triangle" aria-hidden="true"></i> */}
                <div className='behind-addresses'>

                </div>

                <hr width='80%' className='hor-line' style={{ marginRight: 'auto', marginLeft: 'auto', marginTop: '155px', }} />
                <div style={{ color: 'rgb(165 161 161)',
                            marginLeft: '10%',
                            marginTop: '18px',
                            marginBottom: '18px' }}>
                    Copyright © { new Date().getFullYear() } All rights reserved.
                </div>
            </div>
    );
}

export default ContactUs;