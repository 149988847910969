

import './HomePage.css';
import WebSiteIcon from '../MyComponents/WebSiteIcon/WebSiteIcon';
//import GooglePayButton from '@google-pay/button-react';

import WebsiteLogo from '../aImages/_logo.png';



// https://pixabay.com/illustrations/ai-generated-man-smile-happy-8670239/
import handsomePerson from '../aImages/happy_person_5_trans_1.png';  // this image is for free from the above link

import hiringWhite from '../aImages/hiring_white.png';
import { Link } from 'react-router-dom';
import CountUp from '../MyComponents/CountUp/CountUp';
import Footer from '../MyComponents/Footer/Footer';
import { getJobCategories } from '../Javascript_Functions_to_export/Utils';
import { getHappyPerson } from '../Javascript_Functions_to_export/Utils';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useToast } from '../CustomeHooks/useToast';

import FileUploader from '../MyComponents/Uploader/FileUploader';

import { isValidToken } from '../Javascript_Functions_to_export/Utils';
import Cookie from 'js-cookie';

import { useLocation } from 'react-router-dom';

//import tappayments from '@api/tappayments';


const HomePage = () => {

    const [applicantsNumber, setApplicantsNumber] = useState(0);
    const [candidatesnb, setCandidatesnb] = useState(0);
    const [companiesNumber, setCompaniesNumber] = useState(0);
    const [jobsNumber, setJobsNumber] = useState(0);

    const loc = useLocation();
    const toast = useToast();

    const [file, setFile] = useState();
    const getIsAddingCV = (_isAdding) => {
        //console.log("adding = ", _isAdding);
        //setIsAddingCV(_isAdding);
    }

    const [companyLogo, setEmailAsBase64] = useState('');
    const xx = (_files) => {
        setFile(_files[0]);

        var reader = new FileReader();
        reader.onloadend = function() {
             //console.log("file", _files[0]);
             //console.log("reader.result.split('base64,')[1]", reader.result.split("base64,")[1]);
             //setCompanyLogo64(reader.result.split("base64,")[1]);  // to display an image we need the "base64" part

             setEmailAsBase64(reader.result.split("base64,")[1]);
        }
        reader.readAsDataURL(_files[0]);
    }


    const testPort = () => {
        axios.get(`${process.env.REACT_APP_URL}/candidatesnb`
        )
        .then(res => {
            
            alert(res.data.userID);
        })

        return;

        //var formData = new FormData();
        //formData.append("file", file);
        //console.log("file=", file);

        //var formData = { _fileName: file.name, _URL: file.URL }
        var formData = { logo : companyLogo }

        //console.log("formData=", formData, " ||| file", file);

        axios.post(`${process.env.REACT_APP_URL}/logo`, formData)
       .then((res) => {
            console.log("res=", res);
        })
        .catch(err => {
            console.log("err=", err);
        })
        .finally(() => {
        });

        return;
        //axios.post('https://hiremeworld.com/apply_and_send_email_test', formData)
        axios.post(`${process.env.REACT_APP_URL}/apply_and_send_email_test`, formData)
       .then((res) => {
            console.log("res=", res);
        })
        .catch(err => {
            console.log("err=", err);
        })
        .finally(() => {
        });
    }


    axios.defaults.withCredentials = true;
    useEffect(() => {

    
        //if(!loc.state && localStorag.getItem('Logo')) {
        //    localStorag.removeItem('Logo');
              //alert("remove 'Logo'");
        //}
        //console.log("loc", loc);
        //if(document.referrer.indexOf("localhost:3000") >= 0) {
        //}


        var _token = null;

        if(Cookie.get('token'))
            _token = Cookie.get('token');
        else if(Cookie.get('adminToken'))
            _token = Cookie.get('adminToken');

        // User already has logged in, BUT the cookie has expired
        //if(!_token && localStorag.getItem('Logo')) {
        if(!_token && sessionStorage.getItem('Logo')) {
            alert("Session has expired, please Login again");
            //localStorag.removeItem('Logo');
            sessionStorage.removeItem('Logo');
            window.location.href = process.env.REACT_APP_ORIGIN + "/login";
            return;
        }

        // User has changed or added the token by hand
        if(_token && !isValidToken(_token)) {
            return;
        }

        // If user closes the tab || 
        //         closes the browser ||
        //         or open a new tab
        // before the session expired
        // if(_token && !sessionStorage.getItem('Logo')) {
        //     axios.get(`${process.env.REACT_APP_URL}/logout`, { withCredentials: true })
        //     .then(res => {
        //         if(sessionStorage.getItem('Logo'))
        //             sessionStorage.removeItem('Logo');

        //         window.location.href = process.env.REACT_APP_ORIGI + "/login";
        //     })
        //     .catch(err => {
        //         toast('error', err.response.data.message);
        //     })
        // }

        //axios.get('http://localhost:808x/applicantsnumber')
        axios.get(`${process.env.REACT_APP_URL}/numbers`)
        .then(res => {

            if(res.data.status === "success") {
                setApplicantsNumber(res.data.Applications);
                setCandidatesnb(res.data.Candidates);
                setCompaniesNumber(res.data.Employers);
                setJobsNumber(res.data.Jobs);
            }
            else {
                toast("error", res.data.message);
                console.log("error", res.data);
            }
        })
        .catch(err => {
            console.log(err);
        })

        window.scrollTo(0, 0);
        
        return;

        //axios.get('http://localhost:808x/applicantsnumber')
        axios.get(`${process.env.REACT_APP_URL}/applicantsnumber`)
        .then(res => {
            /*
            console.log("sessionEnded=", res.data.sessionEnded, "expire=", res.data.expire);
            // session will be touched anyway
            if(res.data.sessionEnded && res.data.sessionEnded === true) {
                //console.log("should logout");
                alert("Session has expired.")
                if(localStorag.getItem('Logo'))
                    localStorag.removeItem('Logo');
                window.location.href = process.env.REACT_APP_ORIGI + "/login";
            }*/
            setApplicantsNumber(res.data[0].TotalApplicants);
        })

        
        //axios.get('http://localhost:808x/candidatesnb')
        axios.get(`${process.env.REACT_APP_URL}/candidatesnb`
            //  , {
            //      headers: {
            //          //'Content-Type': 'application/json',
            //          //Accept: "application/json",Access-Control-Allow-Origin
            //          //'Access-Control-Allow-Origin': "http://localhost:3000"
            //          'Access-Control-Allow-Origin': process.env.REACT_APP_ORIGI
            //      } , withCredentials:true 
            // }

        )
        .then(res => {
            setCandidatesnb(res.data[0].CandidatesNumber);
            //console.log("CandidatesNumber", res);
        })

        
        
        //axios.get('http://localhost:808x/companiesnumber')
        axios.get(`${process.env.REACT_APP_URL}/companiesnumber`)
        .then(res => {
            setCompaniesNumber(res.data[0].NumberOfCompanies);
            //console.log("NumberOfCompanies", res);
        })

        
        //axios.get('http://localhost:808x/postedjob_number')
        axios.get(`${process.env.REACT_APP_URL}/postedjob_number`)
        .then(res => {
            setJobsNumber(res.data[0].NumberOfPostedJobs);
            //console.log("NumberOfPostedJobs", res);
        });
        

        // //axios.get('http://localhost:808x/applicantsnumber')
        // axios.get(`${process.env.REACT_APP_URL}/applicantsnumber`//, { withCredentials:true }
        //     //  , {
        //     //      headers: {
        //     //          //'Content-Type': 'application/json',
        //     //          //Accept: "application/json",Access-Control-Allow-Origin
        //     //          //'Access-Control-Allow-Origin': "http://localhost:3000"
        //     //          'Access-Control-Allow-Origin': process.env.REACT_APP_ORIGI
        //     //      }
        //     // }
        //             // headers: {
        //             //     "Access-Control-Allow-Origin": "*",
        //             //     "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        //             //     "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
        //             // }

        //              //headers: {
        //                 //         //"Content-Type": "application/json",
        //                 //     }
        //     // }
        // )
        // .then(res => {
        //     //console.log("applicantsnumber from axios", res);
        //     setApplicantsNumber(res.data[0].TotalApplicants);
        // })

        // Everything about XMLHttpRequest() here: https://javascript.info/xmlhttprequest

        // var xhr = new XMLHttpRequest();
        // //xhr.setDisableHeaderCheck(true); where on the earch ??
        // // open the request with the verb and the url
        // xhr.open('GET', `${process.env.REACT_APP_URL}/applicantsnumber`)
        // xhr.setRequestHeader("Content-Type", "application/json");
        // xhr.addEventListener('load', () => {
        //     // update the state of the component with the result here
        //     console.log("using XMLHttpRequest", JSON.parse(xhr.response)[0].TotalApplicants);
        //     setApplicantsNumber(JSON.parse(xhr.response)[0].TotalApplicants);
        // });
        // // send the request
        // xhr.send();
        // xhr.addEventListener('end', () => {
        //     //setApplicantsNumber(xhr.responseText[0].TotalApplicants);
        //     //setApplicantsNumber(JSON.parse(xhr.response)[0].TotalApplicants);
        // });

        //return;

        //var headers = new Headers();
        //headers.append('Content-Type', 'application/json');
        //headers.append('Accept', 'application/json');
        //headers.append('Access-Controll-Allow-Origin', 'http://localhost:3000');

        // try {
        //     var response = fetch(`${process.env.REACT_APP_URL}/applicantsnumber`, {
        //         method: 'GET',
        //         mode: 'no-cors',         // Pin this value           //'same-origin',
        //         //redirect: 'follow',
        //         credentials: 'include', // Don't forget to specify this if you need cookies
        //         //headers: headers,
        //      })

        //      if(response.ok)
        //         console.log("response.json()", response.json());
        //      else
        //      throw new Error("Data could not be fetched");

        // } catch (error) {
        //     console.log("res 1", error);
        // }

        // var headers = new Headers();
        // headers.append('Content-Type', 'application/json');
        // headers.append('Accept', 'application/json');

        // fetch('http://localhost:808x/postedjob_number', {
        //     method: 'GET', // POST
        //     mode: 'no-cors', //'same-origin',
        //     redirect: 'follow',
        //     credentials: 'include', // Don't forget to specify this if you need cookies
        //     headers: headers,
        //     //body: JSON.stringify({
        //     //    first_name: 'John',
        //     //    last_name: 'Doe'
        //     //})
        // })

        // return;

        // *******     worked correctly, and fetched all countries.    *******
        // axios.get('https://restcountries.com/v3.1/all')    
        // .then(res => {
        //     console.log("res 3", res);
        // });

        // code worked nb 1
        // axios.get('https://hiremeworld.com/test') 
        // .then(res => {
        //     console.log("res 99", res);
        // })
        // .catch((err) => {
        //     console.log("err=", err);
        // })

        // code worked nb 1
        // axios.get('https://hiremeworld.com/applicantsnumbe') 
        // .then(res => {
        //     console.log("applicantsnumbe from live DB = ", res);
        // })
        // .catch((err) => {
        //     console.log("err=", err);
        // })


        // axios.get('https://hiremeworld.com/applicantsnumbe',   //  did not work     
        //     {
        //         headers: { 
        //             //ContentType: 'application/json',
        //             Accept: 'application/json',
        //             //AccessControlAllowOrigin: '*',
        //             //AccessControlRequestMethod: 'GET',
        //         }
        //     }
        // )    
        // .then(res => {
        //     console.log("res 99", res);
        // });


        // var headers = new Headers();
        // //headers.append('Content-Type', 'application/json');
        // headers.append('content-type', 'application/json');
        // headers.append('Accept', 'application/json');

        // fetch('https://hiremeworld.com/applicantsnumbe', {   //  did not work
        //    method: 'GET',
        //    mode: 'cors', //'same-origin',
        //    //redirect: 'follow',
        //    credentials: 'include', // Don't forget to specify this if you need cookies
        //    headers: headers,
        // })
        // .then((res) => {
        //    console.log("res 1", res);
        // });

        /*
        axios.get('<https://hiremeworld.com/api/applicantsnumbe>') 
        .then(res => {
            console.log("res 3", res);
        });

        axios.get(`<https://hiremeworld.com/api/applicantsnumbe>`) 
        .then(res => {
            console.log("res 8", res);
        });

        axios.get('https://hiremeworld.com/api/applicantsnumbe') 
        .then(res => {
            console.log("res 6", res);
        });

        axios.get(`https://hiremeworld.com/api/applicantsnumbe`) 
        .then(res => {
            console.log("res 7", res);
        });
        
        */

        // axios.get(`https://hiremeworld.com/applicantsnumbe`)         // did not work
        // .then(res => {
        //     console.log("res 3", res);
        // });

        // axios.get('https://dummyjson.com/users')    // did not work
        // .then(res => {
        //     console.log("res 3", res);
        // });

        // const api = axios.create({
        //     baseURL: '<https://hiremeworld.com>'
        // });

        // api.get('/applicantsnumbe')          // did not work
        // .then(res => {
        //     console.log("res 5", res);
        // });

        // axios.get('https://hiremeworld.com/applicantsnumbe')   // did not work
        // .then(res => {
        //     console.log("res 3", res);
        // });

        // axios.get('/applicantsnumbe')   //  did not work
        // .then(res => {
        //     console.log("res 4", res);
        // });

        // const http = axios.create({      //  did not work
        //     baseURL: 'https://hiremeworld.com'
        // });

        // http.get('/applicantsnumbe')
        // .then(res => {
        //     console.log("res 5", res);
        // });

        // axios.get('https://hiremeworld.com',     =>  return index.html code
        //     //{
        //         //headers: {
        //         //'Content-Type': 'application/json',
        //         //'Accept': 'application/json'
        //         //}
        //     //}
        // )   // for test, worked and showed the alert
        // .then(res => {
        //     console.log("res 2", res);
        // })
        // .catch(res => {
        //     console.log("res.data.message", res.data.message);
        // })

        

        // axios.get('https://hiremeworld.com:8081') // did not work
        // .then(res => {
        //     console.log("res 3", res.status);
        // })

        // axios.get('https://hiremeworld.com:8443/applicantsnumbe') // did not work
        // .then(res => {
        //     console.log("res 3", res.status);
        // })

        // axios.get('https://hiremeworld.com:8443/applicantsnumbe/') // did not work
        // .then(res => {
        //     console.log("res 4", res.status);
        // })

        // axios.get('https://hiremeworld.com/')   // did not work
        // .then(res => {
        //     alert("nothing");
        // })

        // axios.get('https://hiremeworld.com/applicantsnumbe/') // did not work
        // .then(res => {
        //     console.log(res);
        // })

        // axios.get('https://hiremeworld.com:3306/applicantsnumbe/') // did not work
        // .then(res => {
        //     console.log(res);
        // })
    }, []);

/*
    // Links: 
    // https://www.patreon.com/portal/registration/register-clients
    // https://www.freecodecamp.org/news/how-to-create-a-custom-patreon-button/

    //const PatreonButton = (clientId, amount, redirectURI) => {
    //const clientId = `&client_id=${patreonClientId}`;
    const clientId = '&client_id=u8QNwC8IbT1QTFW1z4Ym1SLPcGgxCBI9C6r0KMuJSMXDSuvga34cYqoDHJaE9iQ-';
    
    //const pledgeLevel = `$&min_cents=${amount}`;
    const pledgeLevel = "$&min_cents=100";

    const v2Params = "&scope=identity%20identity[email]";

    //const redirectUri = `&redirect_uri=${redirectURI}`;
    const redirectUri = "&redirect_uri=hiremeworld.com";

    const href = `https://www.patreon.com/oauth2/become-patron?response_type=code${pledgeLevel}${clientId}${redirectUri}${v2Params}`;
*/

    return(   
        <>
        <div className="big-home-container every-page-container desktop-version">
        {/* <div className="big-home-container every-page-container"> */}

    {/* The five steps to: 1/sign up, 2/verify your bank account, 3/google reviews, 4/ ...  */}
    {/* https://support.google.com/pay/business/answer/7530745?hl=en&ref_topic=7684388&sjid=6291328325036347863-EU */}

    {/* Google Pay API for Web 101: Basics */}
    {/* https://codelabs.developers.google.com/codelabs/gpay-web-101#6 */}

    {/* to customize the button */}
    {/* https://developers.google.com/pay/api/web/guides/resources/customize?utm_source=codelab&utm_medium=post&utm_campaign=pay_integration&utm_term=devrel&utm_content=pay_web_101 */}

    {/* To install the npm and copy the working code */}
    {/* https://github.com/google-pay/google-pay-button/tree/main/src/button-react#readme */}

    {/* <GooglePayButton
            environment="TEST"
            buttonColor="white"
            buttonType="pay"
            buttonRadius="5"
            buttonLocale="ar"
            paymentRequest={{
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: [
                {
                    type: 'CARD',
                    parameters: {
                    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                    allowedCardNetworks: ['MASTERCARD', 'VISA'],
                    },
                    tokenizationSpecification: {
                    type: 'PAYMENT_GATEWAY',
                    parameters: {
                        gateway: 'example',
                        gatewayMerchantId: 'exampleGatewayMerchantId',
                    },
                    },
                },
                ],
                merchantInfo: {
                merchantId: 'BCR2DN4TYPULV7IX',
                merchantName: 'hire me world',
                },
                transactionInfo: {
                totalPriceStatus: 'FINAL',
                totalPriceLabel: 'Total',
                totalPrice: '1.00',
                currencyCode: 'USD',
                countryCode: 'US',
                },
            }}
            onLoadPaymentData={paymentRequest => {
                console.log('load payment data', paymentRequest);
            }}
            /> */}
    
        {/* <a className="patreon-button link-button"
        data-patreon-widget-type="become-patron-button"
        href={href}
        rel="noreferrer"
        target="_blank" >
        
        <svg id="patreon-logo"
            viewBox="10 0 2560 356"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink">
            <g>
            <path d="M1536.54 72.449v76.933h128.24v61.473h-128.24v74.51h128.24v62.921h-206.64V9.529h206.64v62.92h-128.24M2070.82 178.907c0-55.652-37.76-107.434-99.21-107.434-61.95 0-99.21 51.782-99.21 107.434s37.26 107.435 99.21 107.435c61.45 0 99.21-51.783 99.21-107.435zm-278.77 0c0-92.916 66.79-178.093 179.56-178.093 112.26 0 179.05 85.177 179.05 178.093 0 92.916-66.79 178.093-179.05 178.093-112.77 0-179.56-85.177-179.56-178.093zM186.32 131.97c0-31.46-21.299-58.563-54.206-58.563H78.398v117.109h53.716c32.907 0 54.206-27.086 54.206-58.546zM0 9.529h141.788c75.016 0 123.417 56.628 123.417 122.441s-48.401 122.423-123.417 122.423h-63.39v93.893H0V9.529zM492.17 106.314l-41.621 139.382h82.266L492.17 106.314zm73.081 241.972-13.054-41.134H431.69l-13.072 41.134h-83.73L455.882 9.529h72.105l122.442 338.757h-85.178zM782.055 77.277H705.61V9.529h231.793v67.748h-76.951v271.009h-78.397V77.277M2485.08 230.202V9.529h77.91v338.757h-81.78l-121.97-217.78v217.78h-78.4V9.529h81.78l122.46 220.673M1245.68 131.97c0-31.46-21.3-58.563-54.21-58.563h-53.72v117.109h53.72c32.91 0 54.21-27.086 54.21-58.546zM1059.36 9.529h142.29c75 0 123.4 56.628 123.4 122.441 0 47.425-25.17 89.517-67.28 109.369l67.77 106.947h-90.98l-60.03-93.893h-36.78v93.893h-78.39V9.529z" />
            </g>
        </svg>
        </a> */}
    



            <div className="home-container">

            { /*<FileUploade XXX={xx} 
                        isAddingFn={ getIsAddingCV } 
                        _accept=".zip, .pdf, .docx"
                        /> 
                        <button onClick={ testPort }>Send request</button> 
                        */}


                <div className='light-color'>

                </div>

                <div className='dark-color'>

                </div>

                <div className='texts'>
                    <div className='text1'>
                        <div className='right-sign'>&#10004;</div>
                        <div className='stay-connect'>
                            Stay connected to get upcoming job with
                            <font color='#1965CF'><i><b> HireMeWorld</b></i></font>
                        </div>
                        {/* <div className='website-word'> HireMe</div> */}
                    </div>
                    <div className='text2'>
                        Find Your<br/>Perfect <span className='job-word'>Job</span><br/> Platform
                    </div>
                    <div className='text3'>
                        Explore all the most exciting job roles based on your interest and <br/>
                        study major. your dream job is waiting for you.
                    </div>
                    <div className='find-job-button'>
                        <Link to='/search'>
                            Find Jobs
                        </Link>
                    </div>
                </div>

                <div className='handsome-icons'>
                    <div className='big-circle'>

                    </div>
                    <div className='white-circle'>

                    </div>
                    <i className="fa fa-file-pdf-o pdf-icon" aria-hidden="true"></i>

                    <div className='plus-sign'>
                        <i className="fa fa-plus" aria-hidden="true"></i>
                    </div>

                    <i className="fa fa-search search-sign" aria-hidden="true"></i>

                    <div className='upload-div'>
                        <span><i className="fa fa-paperclip paperclip" aria-hidden="true"></i></span>
                        <span className='upload-cv'>Upload CV</span>
                    </div>

                    <img src={ handsomePerson }
                        className='handsome-img'
                        alt='handsome' />
                </div>

            </div>


            <div className='jobs-statistics-section'>

                <img src={ hiringWhite } alt="Hiring" className='bottom-img' />

                <div className='bottom-bg'>
                                <div style={{
                                    fontWeight: '600',
                                }}>Our Community
                                </div>
                                <div className='join-community'>
                                    Join our community of talented<br/>
                                    and professionals by applying<br/>for a job today!
                            </div>
                </div>

                {/* <div className='div-data'>
                    <div className='counting'>
                        <div className='rotated-div'></div>
                        <div className='logo'><i className="fa fa-file-text-o" aria-hidden="true"></i></div>

                        <div className='number companies'>
                            { applicantsNumber >= 2 ? applicantsNumber - 1 + "+" : applicantsNumber }
                        </div>
                        <div className='title'>
                            User{ applicantsNumber >= 2 ? 's' : '' } Application{ applicantsNumber >= 2 ? 's' : '' }
                        </div>
                        
                    </div>
                    <div className="counting">
                        <div className='rotated-div'></div>
                        <div className='logo'><i className="fa fa-building-o" aria-hidden="true"></i></div>
                        <div className='user-applications number'>
                            { companiesNumber >= 2 ? companiesNumber - 1 + "+" : companiesNumber }
                        </div>
                        <div className='title'>Compan{ companiesNumber >= 2 ? 'ies' : 'y' }</div>
                    </div>
                    <div className='counting'>
                        <div className='rotated-div'></div>
                        <div className='logo'><i className="fa fa-users" aria-hidden="true"></i></div>
                        <div className='posted-jobs number'>{ candidatesnb >= 2 ? candidatesnb - 1 + "+" : candidatesnb }</div>
                        <div className='title'>User{ candidatesnb >= 2 ? 's' : '' }</div>
                    </div>
                    <div className='counting'>
                        <div className='rotated-div'></div>
                        <div className='logo'><i className="fa fa-th-large" aria-hidden="true"></i></div>
                        <div className='categories number'>
                            { jobsNumber >= 2 ? jobsNumber - 1 + "+" : jobsNumber }
                        </div>
                        <div className='title'>Job{ jobsNumber >= 2 ? 's' : '' }</div>
                    </div>
                </div> */}
            </div>

            <div className='jobs-statistics-section2'>

            </div>
            <Footer />

        </div>


        <div className='big-home-container every-page-container mobile-version'>

            <div className='nav-bar-mobile'>
                <img src={ WebsiteLogo } 
                    alt="logo" 
                    style={{ zIndex: '80', 
                            width: '45px',
                            height: '40px',
                            position: 'relative',
                            // marginLeft: '5%',
                            // top: '10px',
                            // left: '120px',
                            //left: '75px',
                    }} /> 
                    <h2 style={{ 
                        // marginLeft: '5%', 
                        color: "#096073",
                            }}>
                        HireMeWorld
                    </h2>
                    <div>
                        {/* <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="#3584c0" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3l58.3 0c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24l0-13.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1l-58.3 0c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg> */}
                    </div>
            </div>

            <div className='handsome-man-and-icons'>
                <div className='big-circle'></div>

                <div className='white-circle'></div>
                
                <div className='orange-circle'></div>

                <i className="fa fa-file-pdf-o pdf-icon" aria-hidden="true"></i>

                <div className='plus-sign'>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                </div>

                <div className='plus-sign2'>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                </div>

                <i className="fa fa-search search-sign" aria-hidden="true"></i>

                <div className='upload-div'>
                    <span><i className="fa fa-paperclip paperclip" aria-hidden="true"></i></span>
                    <span className='upload-cv'>Upload CV</span>
                </div>

                <img src={ handsomePerson }
                    className='handsome-img'
                    alt='handsome' />
            </div>

            <div className='texts'>
                <div className='text1'>
                    <div className='right-sign'>&#10004;</div>
                    <div className='stay-connect'>
                        Stay connected to get upcoming job with
                        <font color='#1965CF'><i><b> HireMeWorld</b></i></font>
                    </div>
                </div>
                <div className='text2'>
                    <b>Find Your Perfect <span className='job-word'>Job</span> Platform</b>
                </div>
                <div className='text3'>
                    Explore all the most exciting job roles based on your interest and 
                    study major. Your dream job is waiting for you.
                </div>
                <div className='find-job-button'>
                    <Link to='/search'>
                        Find Jobs
                    </Link>
                </div>
            </div>

            

            {/* <div className='div-data'>
                
                <div className='counting'>
                    <div className='col1'>
                        <div className='rotated-div'></div>
                        <div className='logo'><i className="fa fa-file-text-o" aria-hidden="true"></i></div>
                    </div>
                    <div className='col2'>
                        <div className='number companies'>
                            { applicantsNumber >= 2 ? applicantsNumber - 1 + "+" : applicantsNumber }
                        </div>
                        <div className='title'>
                            User{ applicantsNumber >= 2 ? 's' : '' } Application{ applicantsNumber >= 2 ? 's' : '' }
                        </div>
                    </div>
                </div>
                <div className="counting">
                    <div className='col1'>
                        <div className='rotated-div'></div>
                        <div className='logo'><i className="fa fa-building-o" aria-hidden="true"></i></div>
                    </div>
                    <div className='col2'>
                        <div className='user-applications number'>
                            { companiesNumber >= 2 ? companiesNumber - 1 + "+" : companiesNumber }
                        </div>
                        <div className='title'>Compan{ companiesNumber >= 2 ? 'ies' : 'y' }</div>
                    </div>
                </div>
                <div className='counting'>
                    <div className='col1'>
                        <div className='rotated-div'></div>
                        <div className='logo'><i className="fa fa-users" aria-hidden="true"></i></div>
                    </div>
                    <div className='col2'>
                        <div className='posted-jobs number'>{ candidatesnb >= 2 ? candidatesnb - 1 + "+" : candidatesnb }</div>
                        <div className='title'>User{ candidatesnb >= 2 ? 's' : '' }</div>
                    </div>
                </div>
                <div className='counting'>
                    <div className='col1'>
                        <div className='rotated-div'></div>
                        <div className='logo'><i className="fa fa-th-large" aria-hidden="true"></i></div>
                    </div>
                    <div className='col2'>
                        <div className='categories number'>
                            { jobsNumber >= 2 ? jobsNumber - 1 + "+" : jobsNumber }
                        </div>
                        <div className='title'>Job{ jobsNumber >= 2 ? 's' : '' }</div>
                    </div>
                </div>
            </div> */}

            <Footer />
            <br/><br/>
        </div>

        </>
    );

}

export default HomePage;

