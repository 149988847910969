

// https://uxwing.com/png-icon/   free icon


import Cookie from 'js-cookie';
import axios from "axios";

/**
 * CreateTodos : function in the 'Utils' file.
 */
export function createTodos () {

    const todos = [];
    
    //console.log("Inside 'createTodos' function");
    for (let i = 0; i < 5; i++) {
        todos.push({
            id: i,
            text: "Todo #" + i
        });
    }
    sleep();
    return todos;
}


export function sleep(delay = 4000) {
    // link : https://stackoverflow.com/questions/1141302/is-there-a-sleep-function-in-javascript
    let d = new Date().getTime();  
    while (d + delay > new Date().getTime()) {  }
}

export function remove(_arr, _el) {
    
    // The find() method of Array instances returns the first element 
    // in the provided array that satisfies the provided testing function
    var desiredElt = _arr.find(e => e.id === _el.id); 
    
    var eltIndex = _arr.indexOf(desiredElt);
    var deletedElt = _arr.splice(eltIndex, 1);
    
    return deletedElt;
}

// export function getBase64String (imageURL) {

//     // get the "base64 string" of an image 
//     // https://stackoverflow.com/questions/6150289/how-can-i-convert-an-image-into-base64-string-using-javascript
  
//     var fileReader = new FileReader();
//     fileReader.onload = function(fileLoadedEvent) {
//         var srcData = fileLoadedEvent.target.result; // <--- data: base64

//         var newImage = document.createElement('img');
//         newImage.src = srcData;

//         event.target.files[i].base64 = newImage.outerHTML;
//     }
//     fileReader.readAsDataURL(event.target.files[i]);

// }


export function downloadFileFromLink(fileURL, fileName) {
    
    // The problem in downloading the file was, I was downloading a Docx or an image as PDF.
    // the right way is to respect the "extension" file. otherwise the file will be damaged.

    var link = document.createElement("a");
    link.download = fileName;
    link.href = fileURL;
    document.body.appendChild(link); 
    link.click();
    document.body.removeChild(link);
}


export function formatDate(_date) {

}

export function formatCurrency() {
    
}

// https://github.com/lukes/ISO-3166-Countries-with-Regional-Codes/blob/master/all/all.csv
export function getCountries() {
    return [
        //{ Id: 0, Name: 'test' },

        { Id: 0, Name: "Select a Country" },
        { Id: 1, Name: "Afghanistan" },
        { Id: 2, Name: "Aland Islands" },
        { Id: 3, Name: "Albania" },
        { Id: 4, Name: "Algeria" },
        { Id: 5, Name: "American Samoa" },
        { Id: 6, Name: "Andorra" },
        { Id: 7, Name: "Angola" },
        { Id: 8, Name: "Anguilla" },
        { Id: 9, Name: "Antarctica" },
        { Id: 10, Name: "Antigua and Barbuda" },
        { Id: 11, Name: "Argentina" },
        { Id: 12, Name: "Armenia" },
        { Id: 13, Name: "Aruba" },
        { Id: 14, Name: "Australia" },
        { Id: 15, Name: "Austria" },
        { Id: 16, Name: "Azerbaijan" },
        { Id: 17, Name: "Bahamas" },
        { Id: 18, Name: "Bahrain" },
        { Id: 19, Name: "Bangladesh" },
        { Id: 20, Name: "Barbados" },
        { Id: 21, Name: "Belarus" },
        { Id: 22, Name: "Belgium" },
        { Id: 23, Name: "Belize" },
        { Id: 24, Name: "Benin" },
        { Id: 25, Name: "Bermuda" },
        { Id: 26, Name: "Bhutan" },
        { Id: 27, Name: "Bolivia Plurinational State of" },
        { Id: 28, Name: "Bonaire, Sint Eustatius and Saba" },
        { Id: 29, Name: "Bosnia and Herzegovina" },
        { Id: 30, Name: "Botswana" },
        { Id: 31, Name: "Bouvet Island" },
        { Id: 32, Name: "Brazil" },
        { Id: 33, Name: "British Indian Ocean Territory" },
        { Id: 34, Name: "Brunei Darussalam" },
        { Id: 35, Name: "Bulgaria" },
        { Id: 36, Name: "Burkina Faso" },
        { Id: 37, Name: "Burundi" },
        { Id: 38, Name: "Cabo Verde" },
        { Id: 39, Name: "Cambodia" },
        { Id: 40, Name: "Cameroon" },
        { Id: 41, Name: "Canada" },
        { Id: 42, Name: "Cayman Islands" },
        { Id: 43, Name: "Central African Republic" },
        { Id: 44, Name: "Chad" },
        { Id: 45, Name: "Chile" },
        { Id: 46, Name: "China" },
        { Id: 47, Name: "Christmas Island" },
        { Id: 48, Name: "Cocos (Keeling) Islands" },
        { Id: 49, Name: "Colombia" },
        { Id: 50, Name: "Comoros" },
        { Id: 51, Name: "Congo" },
        { Id: 52, Name: "Congo, Democratic Republic of the" },
        { Id: 53, Name: "Cook Islands" },
        { Id: 54, Name: "Costa Rica" },
        { Id: 55, Name: "Cote d'Ivoire" },
        { Id: 56, Name: "Croatia" },
        { Id: 57, Name: "Cuba" },
        { Id: 58, Name: "Curacao" },
        { Id: 59, Name: "Cyprus" },
        { Id: 60, Name: "Czechia" },
        { Id: 61, Name: "Denmark" },
        { Id: 62, Name: "Djibouti" },
        { Id: 63, Name: "Dominica" },
        { Id: 64, Name: "Dominican Republic" },
        { Id: 65, Name: "Ecuador" },
        { Id: 66, Name: "Egypt" },
        { Id: 67, Name: "El Salvador" },
        { Id: 68, Name: "Equatorial Guinea" },
        { Id: 69, Name: "Eritrea" },
        { Id: 70, Name: "Estonia" },
        { Id: 71, Name: "Eswatini" },
        { Id: 72, Name: "Ethiopia" },
        { Id: 73, Name: "Falkland Islands (Malvinas)" },
        { Id: 74, Name: "Faroe Islands" },
        { Id: 75, Name: "Fiji" },
        { Id: 76, Name: "Finland" },
        { Id: 77, Name: "France" },
        { Id: 78, Name: "French Guiana" },
        { Id: 79, Name: "French Polynesia" },
        { Id: 80, Name: "French Southern Territories" },
        { Id: 81, Name: "Gabon" },
        { Id: 82, Name: "Gambia" },
        { Id: 83, Name: "Georgia" },
        { Id: 84, Name: "Germany" },
        { Id: 85, Name: "Ghana" },
        { Id: 86, Name: "Gibraltar" },
        { Id: 87, Name: "Greece" },
        { Id: 88, Name: "Greenland" },
        { Id: 89, Name: "Grenada" },
        { Id: 90, Name: "Guadeloupe" },
        { Id: 91, Name: "Guam" },
        { Id: 92, Name: "Guatemala" },
        { Id: 93, Name: "Guernsey" },
        { Id: 94, Name: "Guinea" },
        { Id: 95, Name: "Guinea-Bissau" },
        { Id: 96, Name: "Guyana" },
        { Id: 97, Name: "Haiti" },
        { Id: 98, Name: "Heard Island and McDonald Islands" },
        { Id: 99, Name: "Holy See" },
        { Id: 100, Name: "Honduras" },
        { Id: 101, Name: "Hong Kong" },
        { Id: 102, Name: "Hungary" },
        { Id: 103, Name: "Iceland" },
        { Id: 104, Name: "India" },
        { Id: 105, Name: "Indonesia" },
        { Id: 106, Name: "Iran" },  //, Islamic Republic of" },
        { Id: 107, Name: "Iraq" },
        { Id: 108, Name: "Ireland" },
        { Id: 109, Name: "Isle of Man" },
        { Id: 110, Name: "Israel" },
        { Id: 111, Name: "Italy" },
        { Id: 112, Name: "Jamaica" },
        { Id: 113, Name: "Japan" },
        { Id: 114, Name: "Jersey" },
        { Id: 115, Name: "Jordan" },
        { Id: 116, Name: "Kazakhstan" },
        { Id: 117, Name: "Kenya" },
        { Id: 118, Name: "Kiribati" },
        { Id: 119, Name: "Korea, Democratic People's Republic of" },
        { Id: 120, Name: "Korea, Republic of" },
        { Id: 121, Name: "Kuwait" },
        { Id: 122, Name: "Kyrgyzstan" },
        { Id: 123, Name: "Lao People's Democratic Republic" },
        { Id: 124, Name: "Latvia" },
        { Id: 125, Name: "Lebanon" },
        { Id: 126, Name: "Lesotho" },
        { Id: 127, Name: "Liberia" },
        { Id: 128, Name: "Libya" },
        { Id: 129, Name: "Liechtenstein" },
        { Id: 130, Name: "Lithuania" },
        { Id: 131, Name: "Luxembourg" },
        { Id: 132, Name: "Macao" },
        { Id: 133, Name: "Madagascar" },
        { Id: 134, Name: "Malawi" },
        { Id: 135, Name: "Malaysia" },
        { Id: 136, Name: "Maldives" },
        { Id: 137, Name: "Mali" },
        { Id: 138, Name: "Malta" },
        { Id: 139, Name: "Marshall Islands" },
        { Id: 140, Name: "Martinique" },
        { Id: 141, Name: "Mauritania" },
        { Id: 142, Name: "Mauritius" },
        { Id: 143, Name: "Mayotte" },
        { Id: 144, Name: "Mexico" },
        { Id: 145, Name: "Micronesia, Federated States of" },
        { Id: 146, Name: "Moldova, Republic of" },
        { Id: 147, Name: "Monaco" },
        { Id: 148, Name: "Mongolia" },
        { Id: 149, Name: "Montenegro" },
        { Id: 150, Name: "Montserrat" },
        { Id: 151, Name: "Morocco" },
        { Id: 152, Name: "Mozambique" },
        { Id: 153, Name: "Myanmar" },
        { Id: 154, Name: "Namibia" },
        { Id: 155, Name: "Nauru" },
        { Id: 156, Name: "Nepal" },
        { Id: 157, Name: "Netherlands, Kingdom of the" },
        { Id: 158, Name: "New Caledonia" },
        { Id: 159, Name: "New Zealand" },
        { Id: 160, Name: "Nicaragua" },
        { Id: 161, Name: "Niger" },
        { Id: 162, Name: "Nigeria" },
        { Id: 163, Name: "Niue" },
        { Id: 164, Name: "Norfolk Island" },
        { Id: 165, Name: "North Macedonia" },
        { Id: 166, Name: "Northern Mariana Islands" },
        { Id: 167, Name: "Norway" },
        { Id: 168, Name: "Oman" },
        { Id: 169, Name: "Pakistan" },
        { Id: 170, Name: "Palau" },
        { Id: 171, Name: "Palestine, State of" },
        { Id: 172, Name: "Panama" },
        { Id: 173, Name: "Papua New Guinea" },
        { Id: 174, Name: "Paraguay" },
        { Id: 175, Name: "Peru" },
        { Id: 176, Name: "Philippines" },
        { Id: 177, Name: "Pitcairn" },
        { Id: 178, Name: "Poland" },
        { Id: 179, Name: "Portugal" },
        { Id: 180, Name: "Puerto Rico" },
        { Id: 181, Name: "Qatar" },
        { Id: 182, Name: "Reunion" },
        { Id: 183, Name: "Romania" },
        { Id: 184, Name: "Russian Federation" },
        { Id: 185, Name: "Rwanda" },
        { Id: 186, Name: "Saint Barthelemy" },
        { Id: 187, Name: "Saint Helena" },  //Ascension and Tristan da Cunha" },
        { Id: 188, Name: "Saint Kitts and Nevis" },
        { Id: 189, Name: "Saint Lucia" },
        { Id: 190, Name: "Saint Martin (French part)" },
        { Id: 191, Name: "Saint Pierre and Miquelon" },
        { Id: 192, Name: "Saint Vincent and the Grenadines" },
        { Id: 193, Name: "Samoa" },
        { Id: 194, Name: "San Marino" },
        { Id: 195, Name: "Sao Tome and Principe" },
        { Id: 196, Name: "Saudi Arabia" },
        { Id: 197, Name: "Senegal" },
        { Id: 198, Name: "Serbia" },
        { Id: 199, Name: "Seychelles" },
        { Id: 200, Name: "Sierra Leone" },
        { Id: 201, Name: "Singapore" },
        { Id: 202, Name: "Sint Maarten (Dutch part)" },
        { Id: 203, Name: "Slovakia" },
        { Id: 204, Name: "Slovenia" },
        { Id: 205, Name: "Solomon Islands" },
        { Id: 206, Name: "Somalia" },
        { Id: 207, Name: "South Africa" },
        { Id: 208, Name: "South Georgia" }, //and the South Sandwich Islands" },
        { Id: 209, Name: "South Sudan" },
        { Id: 210, Name: "Spain" },
        { Id: 211, Name: "Sri Lanka" },
        { Id: 212, Name: "Sudan" },
        { Id: 213, Name: "Suriname" },
        { Id: 214, Name: "Svalbard and Jan Mayen" },
        { Id: 215, Name: "Sweden" },
        { Id: 216, Name: "Switzerland" },
        { Id: 217, Name: "Syrian Arab Republic" },
        { Id: 218, Name: "Taiwan, Province of China" },
        { Id: 219, Name: "Tajikistan" },
        { Id: 220, Name: "Tanzania, United Republic of" },
        { Id: 221, Name: "Thailand" },
        { Id: 222, Name: "Timor-Leste" },
        { Id: 223, Name: "Togo" },
        { Id: 224, Name: "Tokelau" },
        { Id: 225, Name: "Tonga" },
        { Id: 226, Name: "Trinidad and Tobago" },
        { Id: 227, Name: "Tunisia" },
        { Id: 228, Name: "Turkiye" },
        { Id: 229, Name: "Turkmenistan" },
        { Id: 230, Name: "Turks and Caicos Islands" },
        { Id: 231, Name: "Tuvalu" },
        { Id: 232, Name: "Uganda" },
        { Id: 233, Name: "Ukraine" },
        { Id: 234, Name: "United Arab Emirates" },
        { Id: 235, Name: "United Kingdom of Great Britain" }, // and Northern Ireland" },
        { Id: 236, Name: "United States of America" },
        { Id: 237, Name: "United States Minor Outlying Islands" },
        { Id: 238, Name: "Uruguay" },
        { Id: 239, Name: "Uzbekistan" },
        { Id: 240, Name: "Vanuatu" },
        { Id: 241, Name: "Venezuela, Bolivarian Republic of" },
        { Id: 242, Name: "Viet Nam" },
        { Id: 243, Name: "Virgin Islands (British)" },
        { Id: 244, Name: "Virgin Islands (U.S.)" },
        { Id: 245, Name: "Wallis and Futuna" },
        { Id: 246, Name: "Western Sahara" },
        { Id: 247, Name: "Yemen" },
        { Id: 248, Name: "Zambia" },
        { Id: 249, Name: "Zimbabwe" },
        
        //{ Id: 10, Name: 'Any'},
        //{ Id: 20, Name: 'Female'},
        //{ Id: 30, Name: 'Male'},
    ];
}

export function getJobCategories() {
        return [
            { Id: 0, Name: 'Select a Category', ImageURL : '' },
            { Id: 10, Name: 'Accountant', ImageURL : '' },
            { Id: 20, Name: 'Agriculture', ImageURL : '' },
            { Id: 30, Name: 'Art', ImageURL : '' },
            { Id: 40, Name: 'Business', ImageURL : '' },
            { Id: 50, Name: 'Communications', ImageURL : '' },
            { Id: 60, Name: 'Community and Social Services', ImageURL : '' },
            { Id: 70, Name: 'Construction', ImageURL : '' },
            { Id: 80, Name: 'Education', ImageURL : '' },
            { Id: 90, Name: 'Engineering', ImageURL : '' },
            { Id: 100, Name: 'Farming', ImageURL : '' },
            { Id: 110, Name: 'Finance', ImageURL : '' },
            { Id: 120, Name: 'Food and Serving Related', ImageURL : '' },
            { Id: 130, Name: 'Government', ImageURL : '' },
            { Id: 140, Name: 'Health care', ImageURL : '' },
            { Id: 150, Name: 'Human Resources', ImageURL : '' },
            { Id: 160, Name: 'Information technology', ImageURL : '' },
            { Id: 170, Name: 'Journalist', ImageURL : '' },
            { Id: 180, Name: 'Law', ImageURL : '' },
            { Id: 190, Name: 'Management', ImageURL : '' },
            { Id: 200, Name: 'Manufacturing', ImageURL : '' },
            { Id: 210, Name: 'Marketing', ImageURL : '' },
            { Id: 220, Name: 'Other', ImageURL : '' },            
            { Id: 230, Name: 'Sales', ImageURL : '' },
            { Id: 240, Name: 'Transportation', ImageURL : '' },
    ];
}

export function getEmploymentTypes() {
    return [
        //{ Id: 0, Name: 'test' }, 

        { Id: 10, Name: 'Not Specified' }, 
        { Id: 20, Name: 'Full Time' }, 
        { Id: 30, Name: 'Part Time' }, 
        { Id: 40, Name: 'Freelancing' },
        { Id: 50, Name: 'Contract' },
        { Id: 60, Name: 'Temporary' },
        { Id: 70, Name: 'Volunteer' },
        { Id: 80, Name: 'Internship' }
    ];
}

export function getWorkPlaces() {
    return [
        //{ Id: 0, Name: 'test' }, 

        { Id: 10, Name: 'Not Specified' },
        { Id: 20, Name: 'On-Site' },
        { Id: 30, Name: 'Remotly' },
        { Id: 40, Name: 'Hybrid' },
    ]
};

export function getGenders() {
    return [
        //{ Id: 0, Name: 'test' }, 

        { Id: 10, Name: 'Any'},
        { Id: 20, Name: 'Female'},
        { Id: 30, Name: 'Male'},
    ];
}

export function showLocalTime(itemJob) {

    if(itemJob.PostingDate.indexOf("ago") >= 0)
        return itemJob;

    //console.log("in utils");
    var millisDiff = new Date().getTime() - new Date(itemJob.PostingDate).getTime();
    
    if(millisDiff < 0)
        millisDiff = -millisDiff;

    var minDiff = Math.ceil(millisDiff / 1000 / 60);

    if(minDiff === 1) {    // 1 min ago
        itemJob.PostingDate = "1 minute ago";
    }
    else if(minDiff <= 59) {    // before 59 mins
        itemJob.PostingDate = minDiff + " minutes ago"; 
    }
    else if(minDiff <= 100) {
        itemJob.PostingDate = "1 hour ago"; 
    }
    else if(minDiff <= 12 * 60) {    // before 5 hours
        itemJob.PostingDate = Math.ceil(minDiff / 60, 0) + " hours ago"; 
    }  
    else {
        //debugger;
        //console.log(itemJob.PostingDate);

        var datetimeMilli = new Date(itemJob.PostingDate).getTime();
        var d = new Date();
        datetimeMilli += -d.getTimezoneOffset() * 60000; // 1 * timeZoneOffset 
        var dateLocal = new Date(datetimeMilli).toLocaleString('en-US', { hour12: false });
        //console.log(dateLocal);

        dateLocal = dateLocal.slice(0, dateLocal.indexOf(","));
        //debugger;
        var monthNb = dateLocal.slice(0, dateLocal.indexOf("/"));
        var monthSt;
        monthNb == 1 ? monthSt = "Jan" :
        monthNb == 2 ? monthSt = "Feb" :
        monthNb == 3 ? monthSt = "Mar" :
        monthNb == 4 ? monthSt = "Apr" :
        monthNb == 5 ? monthSt = "May" :
        monthNb == 6 ? monthSt = "Jun" :
        monthNb == 7 ? monthSt = "Jul" :
        monthNb == 8 ? monthSt = "Aug" :
        monthNb == 9 ? monthSt = "Sep" : 
        monthNb == 10 ? monthSt = "Oct" :
        monthNb == 11 ? monthSt = "Nov" :
        monthSt = "Dec";

        itemJob.PostingDate = monthSt + dateLocal.slice(dateLocal.indexOf("/"));
    }
    return itemJob;
}


// This function is only used in admin page
export function showLocalDateAndTime(itemJob) {

    var datetimeMilli = new Date(itemJob.PostingDate).getTime();
    //var d = new Date();
    //var delet = -d.getTimezoneOffset();
    //delet = delet + 0
    //console.log("delet", delet);
    //datetimeMilli += -d.getTimezoneOffset() * 60000; // 1 * timeZoneOffset 
    var dateLocal = new Date(datetimeMilli).toLocaleString('en-US', { hour12: false });
    
    dateLocal = dateLocal.replace(",", '');
    
    //console.log(dateLocal);
    //debugger;
    var monthNb = dateLocal.slice(0, dateLocal.indexOf("/"));
    var monthSt;
    monthNb == 1 ? monthSt = "Jan" :
    monthNb == 2 ? monthSt = "Feb" :
    monthNb == 3 ? monthSt = "Mar" :
    monthNb == 4 ? monthSt = "Apr" :
    monthNb == 5 ? monthSt = "May" :
    monthNb == 6 ? monthSt = "Jun" :
    monthNb == 7 ? monthSt = "Jul" :
    monthNb == 8 ? monthSt = "Aug" :
    monthNb == 9 ? monthSt = "Sep" : 
    monthNb == 10 ? monthSt = "Oct" :
    monthNb == 11 ? monthSt = "Nov" :
    monthSt = "Dec";

    //itemJob.PostingDate = monthSt + dateLocal.slice(dateLocal.indexOf("/"));

    return monthSt + dateLocal.slice(dateLocal.indexOf("/"));
}

const decrypt = (salt, encoded) => {
const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
return encoded
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join("");
};

export function getUserFromCookie(cookie) {
    
    var user = {};
    var cookieAsObject = {};
    var cookieInJsonFormat = null;

    if(cookie) {
        //cookieInJsonFormat = cookie.replaceAll("|||", "\"")
        cookieInJsonFormat = decrypt("salt", cookie);
    
        if(cookieInJsonFormat)
        {
            // after decode it (cookie from client side)
            //token={"ID":26,"Username":"employer","CompanyEmail":"saeedchebli@hiremecominfo.com","AccountType":1}

            // after decode it (cookie from server side)
            //{"user":{"ID":26,"Username":"employer","CompanyEmail":"s.chebli@hotmail.com","AccountType":1}}
            try {
                cookieAsObject = JSON.parse(decodeURIComponent(cookieInJsonFormat));     //set from server side
                //console.log("cookieAsObject", cookieAsObject);
                if(cookieInJsonFormat && cookieAsObject) {
                    user.ID = parseInt(cookieAsObject.ID);
                    user.Username = cookieAsObject.Username;
                    user.CompanyEmail = cookieAsObject.CompanyEmail;
                    user.AccountType = parseInt(cookieAsObject.AccountType);
                    //user.Logo = tempUser.Logo;  // removed because cookie cannot hold more than 4096 caracteres
                }
            } catch (error) {
                // if ba3besing bl cookie, zetto barra
                console.log("Cookie is not valid:", error);
                
                Cookie.remove('token'); // this line only works if the user adds the 'token' manually 

                axios.get(`${process.env.REACT_APP_URL}/logout`, { withCredentials: true })
                .then(res => {
                    if(sessionStorage.getItem('Logo'))
                        sessionStorage.removeItem('Logo');
                    alert("Session has been added or modified manually, please Login again");
                    window.location.href = process.env.REACT_APP_ORIGIN + "/login";
                })
                .catch(err => {
                    //toast('error', err.response.data.message);
                })
            }
        }
    }

    return user;
}

export function isValidToken(encryptedToken) {
    try {
        var decCookie = decrypt("salt", encryptedToken);
        var cookieAsJson = JSON.parse(decCookie);
        //console.log("initial cookie", cookieAsJson);
        return true;
    }
    catch(e) {

        Cookie.remove('token'); // this line only works if the user adds the 'token' manually 

        axios.get(`${process.env.REACT_APP_URL}/logout`, { withCredentials: true })
        .then(res => {
            if(sessionStorage.getItem('Logo'))
                sessionStorage.removeItem('Logo');
            alert("Session has been added or modified manually, please Login again");
            window.location.href = process.env.REACT_APP_ORIGIN + "/login";
        })
        .catch(err => {
            //toast('error', err.response.data.message);
        })
    }
}
