

import './Dialogue.css';
import { useState } from 'react';

const Dialogue = ({ title, message, closeFn, contBgColor }) => {

    const [step, setStep] = useState(1);

    const _closeDialog = () => {
        closeFn(true);
    }

    const verify = () => {
        setStep(2);
    }

    const back = () => {
        setStep(1);
    }

    return(<div className='dialog-container' style={{ background: contBgColor }}>
            <div className='dialog-box'>

                <div className='first-line' >
                    <div className='title'>
                        { title }
                    </div>
                    <div className='close-btn' onClick={ _closeDialog }>
                        <svg xmlns="http://www.w3.org/2000/svg" 
                            viewBox="0 0 384 512"
                            width="30" height="30">
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                    </div>
                </div>

                    { step === 1 ?
                    <>
                    
                        <div className='second-line'>
                            <div>
                                { message }
                            </div>
                            <div>
                                <form>
                                    <input name='lb-code'
                                        className='code-field'
                                        type='text'
                                        value='+ 961'
                                        maxLength= "9" />
                                    <input name='phone'
                                        className='phone-field'
                                        type='text'
                                        placeholder='71 / 123 456'
                                        maxLength= "9" />
                                </form>
                            </div>
                        </div>
                        
                        <div className='buttons'>
                            <button type='button'
                                    className='btn primary'
                                    onClick={ verify }>Verify</button>
                            {/* <button type='button' className='button success'>Next</button>
                            <button type='button' className='button danger'>Back</button> */}
                        </div>
                    </>
                    : 
                <>
                    <div className='second-line'>
                        Please check your phone for a whatsapp message with your code.<br/>
                        Your code is 6 digits long.
                        <form>
                            <input type='text' maxLength='6' />
                            We sent your code to: +******52
                        </form>
                    </div>
                    <div className='buttons'>
                        <form>
                            <button type='button' 
                                    className='btn primary'
                                    onClick={ back }>Back</button>
                            <button type='button' className='btn primary'>Continue</button>
                        </form>
                    </div>
                </>
            }
            </div>
    </div>)
}

export default Dialogue;