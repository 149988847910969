


import { useEffect, useState } from "react";
import axios from "axios";
import './Admin.css';
import { getUserFromCookie } from '../../Javascript_Functions_to_export/Utils';
import { useNavigate } from "react-router-dom";

import Cookie from 'js-cookie';

import { useToast } from "../../CustomeHooks/useToast";

import Dialogue from "../Dialogue/Dialogue";

import { showLocalDateAndTime } from '../../Javascript_Functions_to_export/Utils';

const Admin = () => {

    const navigate = useNavigate();
    const toast = useToast();

    const [companyName, setCompanyName] = useState('');
    const [jobs, setJobs] = useState();
    const [logs, setLogs] = useState();


    const [applicantsNumber, setApplicantsNumber] = useState(0);
    const [candidatesnb, setCandidatesnb] = useState(0);
    const [companiesNumber, setCompaniesNumber] = useState(0);
    const [jobsNumber, setJobsNumber] = useState(0);

    useEffect(() => {
        
        //setInterval(() => {
            //console.log("refreshed");

            //var x = document.getElementById("c"); 
            //x.play();

            //var a = new Audio("C:/Users/Admin/Downloads/s.mp3");
            //a.play();

            axios.get(`${process.env.REACT_APP_URL}/numbers`)
            .then(res => {

                if(res.data.status === "success") {
                    setApplicantsNumber(res.data.Applications);
                    setCandidatesnb(res.data.Candidates);
                    setCompaniesNumber(res.data.Employers);
                    setJobsNumber(res.data.Jobs);
                }
                else {
                    toast("error", res.data.message);
                    console.log("error", res.data);
                }
            })
            .catch(err => {
                console.log(err);
            })


            //console.log("I am the admin");
            // fetch all inactive jobs to active the one I want. 

            // user navigate to this page manually (using url)
            //if(!document.cookie) {
            if(!Cookie.get('adminToken')) {
                //toast('warning', 'Please login first !');
                navigate('/login');
            }
            else {
                //if(getUserFromCookie(document.cookie).AccountType === 2) {   // Admin=2
                if(getUserFromCookie(Cookie.get('adminToken')).AccountType === 2) {   // Admin=2
                    
                    axios.get(`${process.env.REACT_APP_URL}/admin`)
                    .then((res) => {
                        setJobs(res.data);
                    })
                    .catch((err) => {

                    });

                    axios.get(`${process.env.REACT_APP_URL}/getLogs`)
                    .then((res) => {
                        setLogs(res.data);
                    })
                    .catch((err) => {

                    });

                }
                else {
                    navigate('/login');
                }
            }
        //}, 10000);

    }, []);

    axios.defaults.withCredentials = true;
    const activateJob = (_job) => {

        //console.log("id = ", _job.JobID);
        axios.put(`${process.env.REACT_APP_URL}/activate_job`, 
            { 
                jobID: _job.JobID,
                //CompanyEmail: getUserFromCookie(document.cookie).CompanyEmail
                CompanyEmail: getUserFromCookie(Cookie.get('adminToken')).CompanyEmail
            })
        .then(res => {
            //if(res.data.status === 'success') {
                alert(res.data.message);
                //navigate('/admin');
                //window.location.href = "http://localhost:300x/admin";
                window.location.href = process.env.REACT_APP_ORIGIN + "/admin";
            //}
            //else
                //toast("error", res.data.error);
        })
        .catch(err => {
            if(err.response.status === 401) {
                toast("error", err.response.data.message);
            }
        })
        .finally(() => {
            //setIsSaving(false);
        })
    }

    const deleteCVs = () => {
        // delete 1 month ago CVs programmatically from the /public/XXX path.
    }

    const changeStatus = (e, job) => {
        if(e && e.target)
            setJobs(jobs.map(j => j.JobID === job.JobID ?
                { ...j, isSel: e.target.checked } :
                j ));            
    }

    const [_form, setForm] = useState("");
    const activateSelJobs = () => {


    
        // axios.get(`${process.env.REACT_APP_URL}/pay_online`)
        // .then(res => {
        //     console.log(res);
        //     setForm(res.data);
        // })
        // .catch(e => {
        //     console.log(e);
        // })
        // return;





        // Check if all selected jobs came from the same company.[]
        if(!jobs || jobs.length === 0)
            return;

        var selectedJobs = jobs.filter(j => j.isSel === true);
        
        if(!selectedJobs || selectedJobs.length === 0) {
            toast("error", "selectedJobs.length === 0");
            return;
        }

        var companyID = selectedJobs[0].ID;
        var companyEmail = selectedJobs[0].Email;
        console.log("companyID=", companyID);

        var jobsFromOtherCompany = selectedJobs.filter(j => j.ID !== companyID);
        console.log("jobsFromOtherCompany=", jobsFromOtherCompany);

        if(jobsFromOtherCompany && jobsFromOtherCompany.length > 0) {
            toast("error", "not all jobs are from the same company");
            return;
        }

        var jobsIDs = [];
        var jobsTitles = "";
        
        for (let i = 0; i < selectedJobs.length; i++) {
            jobsIDs.push("'" + selectedJobs[i].JobID + "'");
            jobsTitles += "<li>" + selectedJobs[i].JobTitle + "</li>@@@";
        }

        jobsIDs = jobsIDs.join(",");

        //var jobsTitles = "<li>Test 1</li>@@@<li>Test 2</li>@@@";
        //var jobsIDs = '240';
        
        //console.log("jobsIDs=", jobsIDs, "jobsTitles=", jobsTitles);
        
        // go to server
        //axios.put(`${process.env.REACT_APP_URL}/activate_job`, 
        axios.post(`${process.env.REACT_APP_URL}/activate_job`, 
            { 
                jobsIDs: jobsIDs, 
                jobsTitles : jobsTitles, 
                companyEmail: companyEmail,
                companyName: selectedJobs[0].CompanyName,
            }
            // ,{
            //     //https://medium.com/@stheodorejohn/managing-cookies-with-axios-simplifying-cookie-based-authentication-911e53c23c8a
            //     headers: { 
            //         'Cookie': 'sessionid=abcdef123456', 
            //         'content-Type': 'application/json',
            //         //"Accept": "/",
            //         "Cache-Control": "no-cache",
            //         //"Cookie": document.cookie
            //     }
            // }
        )
        .then(res => {
            if(res.data.status.toLowerCase() === 'success') {
                window.location.href = process.env.REACT_APP_ORIGIN + "/admin";
            }
            else
                toast("error", res.data.message);
        })
        .catch(err => {
            //console.log("error", err);

            if(err.response.status === 401) {
                toast("error", err.response.data.message);
            }
        })
        .finally(() => {
            //setIsSaving(false);
        })
    }
    
    const [openDialog, setOpenDialog] = useState(false);

    const showDialog = () => {
        setOpenDialog(true);
        if (typeof window != 'undefined' && window.document) {
            document.body.style.overflow = 'hidden';
        }
    }

    const closeDialog = (pCloseDialog) => {
        setOpenDialog(false);

        // ref: https://medium.com/@nikhil_gupta/how-to-disable-background-scroll-when-a-modal-side-drawer-is-open-in-react-js-999653a8eebb 
        //if (typeof window != 'undefined' && window.document) {
            document.body.style.overflow = 'unset';
        //}
    }

    return(
    <>


        <div>
            <br/><br/><br/><br/>

            <audio controls="controls" id="c">
                <source src="https://www.computerhope.com/jargon/m/example.mp3" />
            </audio>

            

            <table id="numbers123456" border="1" style={{ position: "relative",
                                       top: "70px"}} >
                <thead>
                    <tr>
                        <th>Applicants</th>
                        <th>Companies</th>
                        <th>Candidates</th>
                        <th>Jobs</th>
                    </tr>
                </thead>
                
                <tbody>
                    <tr>
                        <td>{ applicantsNumber - 1 } +</td>
                        <td>{ companiesNumber - 1} +</td> 
                        <td>{ candidatesnb - 1 } +</td>
                        <td>{ jobsNumber - 1 } +</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br/><br/>



        <div className="admin-container every-page-container">
            


        {/* <button onClick={ activateSelJobs }>Pay</button> */}
        {/* <form align="center" method="post" action="https://pay.sandbox.fasterpay.com/payment/form" name="fasterpay_payment_form" id="fasterpay_payment_form"><input type="hidden" name="description" value="Test order" /><input type="hidden" name="amount" value="1" /><input type="hidden" name="currency" value="USD" /><input type="hidden" name="merchant_order_id" value="1731943015385" /><input type="hidden" name="sign_version" value="v2" /><input type="hidden" name="success_url" value="http://localhost:3000/admin" /><input type="hidden" name="pingback_url" value="http://localhost:3000/admin" /><input type="hidden" name="api_key" value="t_a60494a9ef8646c842a1adb55e5f58" /><input type="hidden" name="hash" value="0c55deadd8e59f733e42836f941195a0d9e08ea4d65f1f78cd08cbc12f6f10d6" /><input type="Submit" value="Pay Now" id="fasterpay_submit"/></form> */}
            {
                // document.cookie && 
                // getUserFromCookie(document.cookie).AccountType === 2 &&

                Cookie.get('adminToken') && 
                getUserFromCookie(Cookie.get('adminToken')).AccountType === 2 &&

                <form>
                    { openDialog && 
                      <Dialogue title="Verification" 
                                message="Please enter you phone number." 
                                closeFn={ closeDialog } 
                                contBgColor = "rgb(131 130 130 / 63%)" /> }
                
                    <div>
                        admin page
                    </div> <br/>
                    
                    Search for a Company Name : 

                    <input name="compamy-name"
                        type="text"
                        value={ companyName }
                        onChange={ (e) => setCompanyName(e.target.value) }
                        placeholder="Company Name"/><br/><br/><br/>

                    <button type="button" onClick={ activateSelJobs }>Activate selected jobs</button><br/><br/>

                    <div className="headers">
                        <span className='header' style={{ width: '80px', }}>Selection</span>
                        <span className='header' style={{ width: '80px', }}>Job ID</span>
                        <span className='header' style={{ width: '100px', }}>Username</span>
                        <span className='header' style={{ width: '210px', }}>Company Name</span>
                        <span className='header' style={{ width: '250px', }}>Email</span>
                        <span className='header' style={{ width: '300px', }}>Job Title</span>
                        <span className='header' style={{ width: '150px', }}>Posted date (LT 24h)</span> 
                        <span className='header' style={{ width: '110px', }}>Is Active</span>
                        {/* <span className='header' style={{ width: '100px', }}>Action</span> */}
                    </div>
                    
                    <div>
                        {
                            jobs &&
                            Array.isArray(jobs) &&
                            jobs.length > 0 &&
                            jobs.filter(job => job.CompanyName.toLowerCase().indexOf(companyName.toLowerCase()) >= 0).map((j, index) => {
                                return(
                                    <div key={ index } className='single-row'>

                                        <span className='value' style={{ width: '100px', }}>
                                            <input type="checkbox"
                                                    style={{ width: '20px', 
                                                            height: '20px', 
                                                            cursor: 'pointer'}}
                                                    
                                                    checked= { j.checked }
                                                    value={ j.checked } 
                                                    onChange={ (e) => changeStatus(e, j) } />
                                        </span>

                                        <span className='value'>
                                            { j.JobID }
                                        </span>
                                        <span className='value' style={{ width: '200px', }}>
                                            { j.CompanyUsername }
                                        </span>
                                        <span className='value' style={{ width: '145px', }}>
                                            { j.CompanyName }
                                        </span>
                                        <span className='value' style={{ width: '250px', }}>
                                            { j.Email }
                                        </span>
                                        <span className='value' style={{ width: '300px', }}>
                                            { j.JobTitle }
                                        </span>
                                        <span className='value' style={{ width: '170px', }}>
                                            {/* { j.PostingDate } */}
                                            
                                            {
                                                //showLocalDateAndTime(j).PostingDate
                                                showLocalDateAndTime(j)
                                                //j.PostingDate
                                            }

                                        </span>
                                        <span className='value' style={{ width: '130px', }}>
                                            { j.Active === 0 ? 'Inactive' : 'Active' }
                                        </span>
                                        
                                    </div>
                                )
                            })
                        }
                    </div>


                    <br/><br/><br/>
                    <br/><br/><br/>
                    <br/><br/><br/>

                    <div>
                        <button type="button" onClick={ showDialog }>Open Dialog</button>
                    </div>
                    <br/><br/><br/>
                    <br/><br/><br/>
                    <br/><br/><br/>
                    <br/><br/><br/>
                    
                    <h3>Logs (GMT date time)</h3>
                    <div>
                        {
                            logs &&
                            Array.isArray(logs) &&
                            logs.length > 0 &&
                            logs.map((log, index) => {
                                return(
                                    <div key={ index }>
                                        <span className='value' style={{ width: '50px', textAlign: 'center' }}>
                                            { log.ID }
                                        </span>
                                        <span className='value' style={{ width: '400px', }}>
                                            { log.Message }
                                        </span>
                                        <span className='value' style={{ width: '230px', }}>
                                            { log.Date }
                                        </span>
                                        <span className='value' style={{ width: '200px', }}>
                                            { log.State }
                                        </span>
                                        <span className='value' style={{ width: '150px', }}>
                                            { log.ErrorNo }
                                        </span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <br/><br/><br/>
                </form>
            }
        </div>
    </>)
}

export default Admin;






                                        // {
                                        //     !j.Active ?
                                        //     <span className='value' style={{ width: '100px',}}>
                                        //         <button name="activate" 
                                        //                 type="button" 
                                        //                 onClick={ () => activateJob(j) }>
                                        //             Activate
                                        //         </button>
                                        //     </span> :
                                        //     ''
                                        // }










